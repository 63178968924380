import {modalTriggerLiveSiteSrc68Evid1232 as modalOpened} from '@wix/bi-logger-events-data/v2'
import {useVisitorBi} from '@wix/yoshi-flow-editor'
import React, {useEffect} from 'react'
import {ConfirmRsvp} from '../chunks/confirm-rsvp'
import {HeadlessError} from '../chunks/headless-error'
import {OrderSummary} from '../chunks/order-summary'
import {SeatingPlanModal} from '../chunks/seating-plan-modal'
import {TicketsDownload} from '../chunks/ticket-download'
import {NativeModal} from './constants'
import {ModalsProps} from './interfaces'

export const Modals = ({modalType, plan, blockScroll, unblockScroll, eventId}: ModalsProps) => {
  const bi = useVisitorBi()

  useEffect(() => {
    if (modalType) {
      blockScroll?.()
      bi.report(modalOpened({modal_name: modalType, event_id: eventId}))
    } else {
      unblockScroll?.()
    }
    return () => {
      unblockScroll?.()
    }
  }, [modalType])

  switch (modalType) {
    case NativeModal.ORDER_SUMMARY:
      return (
        <LazyModalWrapper>
          <OrderSummary />
        </LazyModalWrapper>
      )
    case NativeModal.TICKETS_DOWNLOAD:
      return (
        <LazyModalWrapper>
          <TicketsDownload />
        </LazyModalWrapper>
      )
    case NativeModal.SEATING_PLAN:
      if (!plan) {
        return null
      }

      return <SeatingPlanModal />
    case NativeModal.CONFIRM_RSVP:
      return (
        <LazyModalWrapper>
          <ConfirmRsvp />
        </LazyModalWrapper>
      )
    case NativeModal.HEADLESS_ERROR:
      return (
        <LazyModalWrapper>
          <HeadlessError />
        </LazyModalWrapper>
      )
    default:
      return null
  }
}

const LazyModalWrapper: React.FC = ({children}) => <React.Suspense fallback={null}>{children}</React.Suspense>
